import { ManageAccounts, Person, Visibility } from "@mui/icons-material"

export const OPTIONS = [
    { id: "trial", title: "Trial" },
    { id: "vod", title: "VOD" },
    { id: "webrtc", title: "Webcaster" },
    { id: "transcoding", title: "Transcoding" },
    { id: "h5live", title: "H5Live" },
    { id: "hls", title: "HLS" },
    { id: "secure", title: "Secure" },
    { id: "streamguard", title: "Streamguard" },
    { id: "metadata", title: "Metadata" },
    { id: "allowedOpcodes", title: "Live Processing" },
    { id: "meetEnabled", title: "nanoMeet" },
    { id: "customStreamnames", title: "Custom Streamnames" }
]

export const SUPPORT = [
    { id: "8/5 Best Effort", title: "8/5 Best Effort" },
    { id: "8/5 Pro Support", title: "8/5 Pro Support" },
    { id: "24/7", title: "24/7" },
]

export const METRICS = [
    { id: "basic", title: "Basic" },
    { id: "full", title: "Full" },
    { id: "premium", title: "Premium" },
]

export const OPCODES = [
    { id: "rec", title: "Record" },
    { id: "thumbs", title: "Thumbnails" },
    { id: "none", title: "None" }
]

export const ACCESS_LEVEL_NANO = {
    nanoReadOnly: { id: "nanoReadOnly", title: "Read Only", icon: <Visibility />, color: "info" },
    nanoUser: { id: "nanoUser", title: "User", icon: <Person />, color: "success" },
    nanoAdmin: { id: "nanoAdmin", title: "Admin", icon: <ManageAccounts />, color: "error" }
}

export const LIVE_PROCESSING = {
    thumbs: { id: "thumbs", title: "Thumbnail", underline: "Captures snapshots at specified intervals to quickly review key moments in the stream.", data: [{ id: "interval", limits: { default: 10, min: 10, max: 60 } }] },
    motionclip: { id: "motionclip", title: "Motion Clip", underline: "Generates short sequences of snapshots at defined intervals and durations for dynamic previews.", data: [{ id: "duration", limits: { default: 5, min: 1, max: 60 } }, { id: "interval", limits: { default: 10, min: 10, max: 60 } }] },
    rec: { id: "rec", title: "Recording", underline: "Records the entire stream for later access as Video on Demand.", data: [{ id: "duration", limits: { default: null, min: 1 } }] },
    replay: { id: "replay", title: "Replay", underline: "Allows users to rewind and view past sections of the live stream.", data: [{ id: "duration", limits: { default: null, min: 1 } }] },
}
