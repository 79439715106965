import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Box, Button, Grid, Link, Stack, Tab, Tabs, Typography } from '@mui/material';
import { AspectRatio, BadgeOutlined, BurstMode, BurstModeOutlined, CalendarMonth, CameraOutlined, DeleteOutline, Edit, EditOutlined, Fingerprint, Fitbit, FormatListNumberedOutlined, Image, ImageOutlined, LabelImportantOutlined, LabelOutlined, LinkOutlined, LocalOffer, LocalOfferOutlined, LockOutlined, OpenInNew, PlaylistAddOutlined, Restore, Schedule, SlowMotionVideoOutlined, StopCircleOutlined, Stream, Tag } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

import { useAudience, usePermission } from '../../../utils/helper/hooks';
import permissionConfig from '../../../utils/permissions/permission_config';

import { BASE, DASHBOARD_ABR_TRANSCODE, H5LIVE_EMBED_PLAYER, NEW_STREAM, PLAYOUT, STREAM, STREAMS, WEBCASTER } from '../../../utils/helper/link-config';
import { DECODE_TOKEN } from '../../../utils/helper/methods';
import { deleteVOD } from '../../../utils/bintu/api-requests';

import SnackbarMessage from '../../global/SnackbarMessage';
import ContentTable from '../../global/ContentTable';
import SectionContainer from '../../global/SectionContainer';
import IFrameCodeSnippet from '../../player/IFrameCodeSnippet';
import CodeSnippet from '../../player/CodeSnippet';
import DeleteStream from '../DeleteStream';
import StopStream from '../StopStream';
import ContentDialog from '../../global/ContentDialog';
import DeleteProfile from '../transcoding/DeleteProfile';
import UpdateProfile from '../transcoding/UpdateProfile';
import AddProfile from '../transcoding/AddProfiles';
import UpdateTimecode from '../UpdateTimecode';
import UpdatePushUrl from '../transcoding/UpdatePushUrl';
import UpdateTags from '../UpdateTags';
import MiniPlaybackToken from '../../create/MiniPlaybackToken';
import LiveProcessingOverview from '../opcodes/LiveProcessingOverview';
import EditLiveProcessingDialog from '../opcodes/EditLiveProcessing';

export default function StreamOverview(props) {
    const theme = useTheme();
    const [stream, setStream] = useState(props.stream);
    const { orga, secure, hideStreamUnderline, showOverviewButton } = props;
    const navigate = useNavigate();
    const [token, setToken] = useState(props.token);

    let isSecure = secure // ? (token ? secure : false) : false;
    let hasProcessing = orga.allowedOpcodes?.length > 0;
    let securitySuffix = isSecure ? `?security.jwtoken=${token ? token : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : "";
    let embedUrlSuffix = stream.playout.rtmp.length > 1 ? `group.id=${stream.id}${isSecure ? `&group.security.jwtoken=${token ? token : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}` : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${isSecure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${token ? token : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')
    const [isABR, setIsABR] = useState(stream.playout?.rtmp.length > 1);
    const [playoutURL, setPlayoutURL] = useState([
        { label: "Live Playout Url", link: `${BASE}${PLAYOUT}/${stream.id}${securitySuffix}`, copy: true },
        { label: "iFrame Embed Url", link: `${H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
    ]);
    const rtmpPlayout = [
        { label: "Streamname", value: stream.playout.rtmp[0]?.streamname, copy: true },
        { label: "Url", value: stream.playout.rtmp[0]?.url, copy: true }
    ];

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openStop, setOpenStop] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const nav = (link) => () => { navigate(link); }
    const openInNewTab = (link) => () => { window.open(link, '_blank'); }
    const handleChangePlayoutTab = (e, newPlayoutTab) => { setPlayoutTab(newPlayoutTab); }
    const handleDeleteStream = () => { setOpenDelete(!openDelete) }
    const handleStopStream = () => { setOpenStop(!openStop) }

    const hasSplay = useAudience("splay");
    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);
    const canSetTags = usePermission(permissionConfig.permissionMasks.streamWithId.setTag);
    const canSetPushUrl = usePermission(permissionConfig.permissionMasks.streamWithId.setPushUrl);
    const canSetTimecode = usePermission(permissionConfig.permissionMasks.streamWithId.setTimecode);
    const canSetOpcode = usePermission(permissionConfig.permissionMasks.streamWithId.setOpcode);
    const canRemoveTranscode = usePermission(permissionConfig.permissionMasks.streamWithId.removeTranscode);
    const canAddTranscode = usePermission(permissionConfig.permissionMasks.streamWithId.addTranscode);
    const canDeleteStream = usePermission(permissionConfig.permissionMasks.streamWithId.deleteStream);
    const canStopIngest = usePermission(permissionConfig.permissionMasks.streamWithId.stopIngest);
    const canUnlockStream = usePermission(permissionConfig.permissionMasks.streamWithId.unlockStream);

    const quickActions = [
        { title: "Watch Stream", link: `${PLAYOUT}/${stream.id}`, color: "primary", variant: "contained", startIcon: <SlowMotionVideoOutlined /> },
        ...(canCreateStream ? [{ title: "Start Webcast", link: `${WEBCASTER}/${stream.id}`, color: "primary", variant: "contained", startIcon: <CameraOutlined /> }] : []),
        { title: "Get Instructions", link: `${NEW_STREAM}/${stream.id}`, color: "primary", variant: "contained", startIcon: <FormatListNumberedOutlined /> }
    ];

    const [decodedToken, setDecodedToken] = useState([]);
    const [playoutTab, setPlayoutTab] = useState(0);
    const [playoutDetails, setPlayoutDetails] = useState(stream?.playout?.rtmp);
    const ingest = [
        { label: "RTMP Ingest Streamname", value: stream.ingest?.rtmp.streamname, copy: true },
        { label: "RTMP Ingest Url", value: stream.ingest?.rtmp.url, copy: true }
    ];
    const streamDetails = [
        { label: "Streamgroup Id", value: stream.id, copy: true },
        { label: "Updated at", date: moment(stream.updated_at).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
        ...(!isABR ? [{ label: "Tags", tags: stream.tags, edit: canSetTags ? stream.id : false }] : []),
        ...(!isABR ? [{
            label: "Push Url", copy: stream.pushUrl !== null, edit: canSetPushUrl && stream.id,
            ...(stream.pushUrl === null ? { bool: { is: false } } : { value: stream.pushUrl }),
        }] : []),
        ...(orga?.metadata ? [{ label: "Timecode", bool: { is: stream.timecode }, copy: false, edit: canSetTimecode ? stream.id : false }] : []),
        ...(orga?.metadata && stream.timecode ? [{ label: "Timecode Interval", value: stream.timecode_interval, copy: false }] : []),
    ];

    const handleDecodeToken = (newToken) => {
        let decoded = DECODE_TOKEN(newToken);

        if (decoded) {
            setDecodedToken([
                { label: "JWT token", value: newToken, copy: true },
                { label: "Not Before (nbf)", nbf: moment.unix(decoded.nbf).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                { label: "Expires (exp)", exp: moment.unix(decoded.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)'), copy: false },
                ...(decoded.orgahash ? [{ label: "Entire Organisation", bool: { is: true }, copy: false }] : []),
                ...(decoded.streams ? [{ label: "Stream name(s)", value: `[${decoded.streams.map(s => s)?.join(", ")}]`, copy: true }] : []),
                ...(decoded.tag ? [{ label: "Tag", tag: decoded.tag }] : []),
                ...(decoded.ip ? [{ label: "IP", value: decoded.ip, copy: true }] : []),
                ...(decoded.domain ? [{ label: "Domain", link: decoded.domain, copy: true }] : []),
            ]);
        }

        securitySuffix = secure ? `?security.jwtoken=${newToken ? newToken : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : "";
        embedUrlSuffix = stream.playout.rtmp.length > 1
            ? `group.id=${stream.id}${secure ? `&group.security.jwtoken=${newToken ? newToken : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}`
            : stream.playout.rtmp.map((s, i) => `entry${i > 0 ? i + 1 : ""}.rtmp.streamname=${s.streamname}${secure ? `&entry${i > 0 ? i + 1 : ""}.security.jwtoken=${newToken ? newToken : "[ENTER_PLAYBACK_TOKEN_HERE]"}` : ""}${s.info ? `&entry${i > 0 ? i + 1 : ""}.info.bitrate=${s.info.bitrate}` : ""}`).join('&')

        setPlayoutURL([
            { label: "Live Playout Url", link: `${BASE}${PLAYOUT}/${stream.id}${securitySuffix}`, copy: true },
            { label: "iFrame Embed Url", link: `${H5LIVE_EMBED_PLAYER}${embedUrlSuffix}`, copy: true }
        ]);
        setToken(newToken);
        closeDialog();
    }

    const closeDialog = () => {
        setOpenDialog(false);
        setDialogContent(null);
    }

    const openDialogWithContent = (title, underline, content) => {
        setOpenDialog(true);
        setDialogContent({ title, underline, content });
    };


    const handleAddProfile = () => {
        if (!canAddTranscode) return;
        openDialogWithContent(
            "Add Transcoding Profile",
            "Enhance your stream group with up to 3 transcoding profiles.",
            <AddProfile
                small streamid={stream.id} cancel={closeDialog}
                handleUpdateTranscode={(rtmpObj) => {
                    if (stream?.playout?.rtmp) {
                        setIsABR(true);
                        setPlayoutDetails(rtmpObj);
                    }
                    setPlayoutTab(0);
                    closeDialog();
                }}
            />
        );
    };

    const handleEditProfile = (details) => () => {
        openDialogWithContent(
            "Modify Transcoding Profile",
            "Refine the attributes of your existing transcoding profile.",
            <UpdateProfile
                small details={details} cancel={closeDialog}
                handleUpdateTranscode={(rtmpObj) => {
                    console.log(stream?.playout?.rtmp)
                    if (stream?.playout?.rtmp[0]) {
                        const updatedRtmp = [...stream.playout.rtmp];
                        updatedRtmp[rtmpObj.index] = rtmpObj;
                        setPlayoutDetails(updatedRtmp);
                    }
                    closeDialog();
                }}
            />
        );
    };

    const handleDeleteProfile = (details) => () => {
        if (!canRemoveTranscode) return;
        openDialogWithContent(
            "Delete Transcoding Profile",
            "Confirm the removal of this transcoding profile from your stream group.",
            <DeleteProfile
                small details={details} groupid={stream.id} cancel={closeDialog}
                handleUpdateTranscode={(newRtmp) => {
                    if (newRtmp.length === 1) setIsABR(false);
                    setPlayoutDetails(newRtmp);
                    setPlayoutTab(0);
                    closeDialog();
                }}
            />
        );
    };

    const handleClickedRemove = (label, value) => () => {
        let removableVod = value?.split("/").pop()?.split(".mp4")?.shift();
        openDialogWithContent(`Remove the VOD with streamname: ${removableVod}`, "",
            <Fragment>
                <Typography variant="body1" color="textSecondary">
                    Please confirm that you want to delete this VOD with the streamname <b>{removableVod}</b>.
                </Typography>
                <Stack direction="row" spacing={.5} useFlexGap mt={2}>
                    <Button size="small" variant="contained" onClick={handleRemoveVOD(removableVod, value)}>
                        Remove
                    </Button>
                    <Button size="small" variant="outlined" onClick={closeDialog}>
                        Cancel
                    </Button>
                </Stack>
            </Fragment>
        )
    }

    const updateTranscodes = (label, streamid) => (...values) => {
        let index = playoutDetails.findIndex(p => p.id === streamid);
        let clonedPlayoutDetails = playoutDetails;
        clonedPlayoutDetails[index] = { ...clonedPlayoutDetails[index], [label]: values[0] };
        setPlayoutDetails(clonedPlayoutDetails);
        setStream({ ...stream, playout: { ...stream.playout, rtmp: clonedPlayoutDetails } });
        closeDialog();
    }


    const updated = (...labels) => (...values) => {
        setStream({
            ...stream, [labels[0]]: values[0],
            ...(labels[1] && { [labels[1]]: values[1] })
        });
        closeDialog();
    }

    const handleEditClicked = (label, streamid) => () => {
        let passthrough = streamid === stream.id;

        switch (label) {
            case "Timecode":
                openDialogWithContent(`Update the timecode.`,
                    "You can set the time injection interval between 500 ms and 1 hour. Recommended values are 1000-5000 ms. Set to 0 to disable time injection.",
                    <UpdateTimecode
                        updated={updated("timecode_interval", "timecode")}
                        streamid={streamid} timecode={stream.timecode_interval} cancel={closeDialog}
                    />); break;
            case "Push Url":
                let pushUrl = passthrough ? stream.pushUrl : playoutDetails.filter((p, i) => streamid === p.id)?.map(f => f.pushUrl)[0];
                openDialogWithContent(`Update the pushurl for stream with id: ${streamid}`, "",
                    <UpdatePushUrl
                        streamid={streamid} pushUrl={pushUrl} cancel={closeDialog}
                        updated={passthrough ? updated("pushUrl") : updateTranscodes("pushUrl", streamid)}
                    />); break;
            case "Tags":
                let tags = passthrough ? stream.tags : playoutDetails.filter((p, i) => streamid === p.id)?.map(f => f.tags)[0];
                openDialogWithContent(
                    `Update tags for stream with id: ${streamid}`, "Manage the tags of your stream. You can set, update, or delete them.",
                    <UpdateTags
                        streamid={streamid} tags={tags} cancel={closeDialog}
                        updated={passthrough ? updated("tags") : updateTranscodes("tags", streamid)}
                    />
                ); break;
            case "Token":
                openDialogWithContent(
                    `Create new playback token`, "Generate a new token to secure nanoStream H5Live playbacks.",
                    <MiniPlaybackToken
                        small groupid={streamid}
                        handleUpdateToken={handleDecodeToken}
                    />
                ); break;
            case "Processing":
                openDialogWithContent(
                    `Edit Live Processing`, "Update the enabled processing features and their respective settings.",
                    <EditLiveProcessingDialog
                        hasTranscoding={isABR} stream={stream} cancel={closeDialog}
                        prevOptions={stream.processing} updated={updated("processing")}
                    />
                ); break;
            default: return;
        }
    }

    const handleRemoveVOD = (vod, url) => () => {
        let data = { streamid: stream.id, streamname: vod };

        deleteVOD(data)
            .then((response) => {
                if (response.success) {
                    let updatedWeb = stream.playout.web.filter(webItem => webItem.url !== url);
                    setOpenDialog(false);
                    setStream({ ...stream, playout: { ...stream.playout, web: updatedWeb } });
                }
            }).catch((error) => {
                if (!error.success) {
                    setOpenDialog(false);
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    useEffect(() => { if (isSecure) handleDecodeToken(token); }, [])

    return (
        <Fragment>
            <SnackbarMessage open={showError} close={handleCloseError} type={"error"}>
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                openDialog &&
                <ContentDialog
                    hideActions open={openDialog} close={closeDialog}
                    title={dialogContent?.title}
                    underline={dialogContent?.underline}
                    content={dialogContent?.content}
                />
            }
            <DeleteStream
                open={openDelete} streamid={stream.id}
                deleted={nav(STREAMS)}
                cancel={handleDeleteStream}
            />
            <StopStream
                open={openStop} streamid={stream.id}
                state={stream.state} action={nav(0)}
                cancel={handleStopStream}
            />
            <Grid container spacing={2}>
                {
                    !hideStreamUnderline &&
                    <Grid item xs={12}>
                        <Stack spacing={.8} useFlexGap flexWrap={"wrap"} direction="row">
                            {
                                quickActions.map((q, i) => (
                                    <Button size="small" {...q} onClick={nav(q.link)}>
                                        {q.title}
                                    </Button>
                                ))
                            }
                            {
                                canDeleteStream &&
                                <Button
                                    variant="outlined" color="error" size="small"
                                    startIcon={<DeleteOutline />}
                                    onClick={handleDeleteStream}
                                >
                                    Delete Stream
                                </Button>
                            }
                            {
                                (canStopIngest && canUnlockStream) &&
                                <Button
                                    variant="outlined" color="error" size="small"
                                    startIcon={<StopCircleOutlined />}
                                    onClick={handleStopStream}
                                >
                                    {stream.state === "locked" ? "Unlock" : "Stop"} Stream
                                </Button>
                            }
                        </Stack>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SectionContainer noMargin title={isABR ? "Streamgroup Details" : "Stream Details"}>
                        <ContentTable
                            data={streamDetails}
                            edit={handleEditClicked}
                        />
                        {
                            (!isABR && playoutDetails && canAddTranscode) &&
                            <Fragment>
                                <Typography sx={{ fontStyle: 'italic', color: theme.palette.error.dark }} variant="body2" mt={1}>
                                    This stream has no transcoding profiles.
                                    Without enabling adaptive bitrate, the stream is broadcast in its original quality, which can lead to buffering issues for viewers with slower internet connections.
                                </Typography>
                                <Button
                                    sx={{ mt: 1, mr: 1 }}
                                    size="small"
                                    variant="contained"
                                    onClick={handleAddProfile}
                                    endIcon={<PlaylistAddOutlined />}

                                >
                                    Add profile
                                </Button>
                                <Button
                                    sx={{ mt: 1 }}
                                    size="small"
                                    variant="outlined"
                                    onClick={openInNewTab(DASHBOARD_ABR_TRANSCODE)}
                                    endIcon={<OpenInNew />}

                                >
                                    Explore ABR
                                </Button>
                            </Fragment>
                        }
                    </SectionContainer>
                </Grid>
                {
                    hasProcessing &&
                    <Grid item xs={12} sm={12}>
                        <LiveProcessingOverview
                            stream={stream}
                            handleClicked={handleEditClicked("Processing", stream.id)}
                        />
                    </Grid>
                }
                {
                    (isABR && playoutDetails) &&
                    <Grid item xs={12} md={12}>
                        <SectionContainer
                            noMargin
                            title="Adaptive Bitrate (ABR) Stream overview"
                            underline="Toggle between the tabs for a detailed view of each stream within the stream group."
                            interact={canAddTranscode && playoutDetails.length < 4 ? true : false}
                            button={"Add Profile"}
                            icon={<PlaylistAddOutlined />}
                            clicked={handleAddProfile}
                        >
                            <Tabs value={playoutTab} onChange={handleChangePlayoutTab}>
                                {playoutDetails.map((t, i) => (<Tab key={`pd-${i}`} label={i === 0 ? "Passthrough" : `${i}. Transcode`} />))}
                            </Tabs>
                            {
                                playoutDetails.map((playoutDetail, i) => {
                                    const isPassthrough = i === 0;
                                    const currStream = isPassthrough ? stream : playoutDetails[i];

                                    return (
                                        <Box key={`detail-${i}`} component="div" hidden={!(playoutTab === i)}>
                                            <SectionContainer
                                                contrast small
                                                title={`Stream Details: ${i === 0 ? "Passthrough" : `${i}. Transcode`}`}
                                                underline={`Quality of the adaptive bitrate (ABR) profile.`}
                                            >
                                                <ContentTable
                                                    edit={handleEditClicked}
                                                    data={[
                                                        { label: "Stream id", value: playoutDetail.id, copy: true },
                                                        { label: "Streamname", value: playoutDetail.streamname, copy: true },
                                                        {
                                                            label: "Push Url", copy: currStream.pushUrl !== null, edit: canSetPushUrl && stream.id,
                                                            ...(currStream.pushUrl === null ? { bool: { is: false } } : { value: currStream.pushUrl }),
                                                        },
                                                        { label: "Tags", tags: currStream.tags, copy: false, edit: canSetTags ? playoutDetail.id : false },
                                                        ...(currStream.info ? [{ label: "Resolution", value: `${currStream.info.width === 0 ? "as input" : `${currStream.info.width ? `${currStream.info.width}x${currStream.info.height}` : `n/a`}`}`, }] : []),
                                                        ...(currStream.info ? [{ label: "Bitrate", value: `${currStream.info.bitrate} kbit/s` }] : []),
                                                        ...(currStream.info ? [{ label: "Framerate", value: `${currStream.info.fps === 0 ? "as input" : `${currStream.info.fps ? `${currStream.info.fps} fps` : `n/a`}`}` }] : []),
                                                    ]}
                                                />
                                                {
                                                    !isPassthrough && (canSetPushUrl || canRemoveTranscode) &&
                                                    <Stack sx={{ mt: 1.5 }} spacing={.5} direction="row" useFlexGap flexWrap={"wrap"}>
                                                        {
                                                            canSetPushUrl &&
                                                            <Button
                                                                disabled={!canSetPushUrl} endIcon={<EditOutlined />}
                                                                size="small" variant="contained"
                                                                onClick={handleEditProfile(currStream)}
                                                            >
                                                                Edit Profile
                                                            </Button>
                                                        }
                                                        {
                                                            canRemoveTranscode &&
                                                            <Button
                                                                disabled={!canRemoveTranscode} endIcon={<DeleteOutline />}
                                                                size="small" color="error" variant="outlined"
                                                                onClick={handleDeleteProfile(currStream)}
                                                            >
                                                                Remove Profile
                                                            </Button>
                                                        }
                                                    </Stack>
                                                }
                                            </SectionContainer>
                                        </Box>
                                    )
                                })
                            }
                        </SectionContainer>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SectionContainer
                        noMargin title="Ingest"
                        underline="Copy the info below to your Live Encoder software or hardware (e.g.OBS), or use the nanoStream Webcaster."
                    >
                        <ContentTable data={ingest} />
                    </SectionContainer>
                </Grid>
                {
                    isSecure && hasSplay && decodedToken && token &&
                    <Grid item xs={12}>
                        <SectionContainer
                            noMargin contrastBorder title="Playback Token"
                            underline="This is the playback token that you apply once you click on the web playout link."
                            button="Create new Playback Token"
                            interact icon={<LockOutlined />}
                            clicked={handleEditClicked("Token", stream.id)}
                        >
                            <ContentTable data={decodedToken} />
                        </SectionContainer>
                    </Grid>
                }
                <Grid item xs={12}>
                    <SectionContainer
                        noMargin title="H5Live Playout"
                        underline={'Use this live playout url to access the playback of the stream.'}
                    >
                        <ContentTable data={playoutURL} />
                    </SectionContainer>
                </Grid>
                <Grid item xs={12}>
                    <IFrameCodeSnippet stream={stream} security={token} />
                    <CodeSnippet stream={stream} security={token} />
                </Grid>
                {
                    !isSecure &&
                    <Grid item xs={12}>
                        <SectionContainer noMargin title="RTMP Playout">
                            <ContentTable data={rtmpPlayout} />
                        </SectionContainer>
                    </Grid>
                }
                {
                    orga?.vod &&
                    <Grid item xs={12}>
                        <SectionContainer noMargin title="VOD Playout URLs">
                            <ContentTable
                                auto // remove={handleClickedRemove}
                                data={stream.playout.web
                                    .filter((web, i) => web.type === "vod")
                                    .map((playoutUrl, i) => ({
                                        label: "VOD Playout",
                                        custom: (
                                            <Typography variant="body2">
                                                <Link underline="hover" href={playoutUrl.url} target="_blank">
                                                    {playoutUrl.url}
                                                </Link>
                                                {playoutUrl.size ? ` (${(playoutUrl.size / (1024 * 1024)).toFixed(2)} MB)` : ""}
                                            </Typography>
                                        ),
                                        copy: true, // remove: i > 0 ? playoutUrl.url : false,
                                        copyContent: playoutUrl.url,

                                    }))}
                            />
                        </SectionContainer>
                    </Grid>
                }
                {
                    orga?.vod && stream.playout.hls && // isSecure &&
                    <Grid item xs={12}>
                        <SectionContainer
                            noMargin title="HLS Playout"
                            underline="To see the H5Live Playout see the H5Live section above."
                        >
                            <ContentTable
                                auto data={[{ label: "Live (HLS Legacy / Standard Latency)", link: stream.playout.hls[0]?.url, copy: true }]}
                            />
                        </SectionContainer>
                    </Grid>
                }
            </Grid>
        </Fragment >
    )
}
